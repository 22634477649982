import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Stack } from '@twilio-paste/stack';
import { Paragraph } from '@twilio-paste/paragraph';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { Anchor } from '@twilio-paste/anchor';
import { Disclosure, DisclosureHeading, DisclosureContent } from '@twilio-paste/disclosure';
import { InlineCode } from '../../../components/Typography';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/getting-started/faqs/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <h1>{props.pageContext.frontmatter.title}</h1>
      <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Engineering`}</h2>
        <Stack orientation="vertical" spacing="space140" mdxType="Stack">
  <Disclosure visible mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      What browser support does Paste have?
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <Paragraph mdxType="Paragraph">
        We support the latest and latest minus one versions of all modern browsers on the market today. Most notable
        mentions include:
      </Paragraph>
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">Google Chrome</ListItem>
        <ListItem mdxType="ListItem">Safari</ListItem>
        <ListItem mdxType="ListItem">Firefox</ListItem>
        <ListItem mdxType="ListItem">MS Edge</ListItem>
        <ListItem mdxType="ListItem">Chromium Edge</ListItem>
      </UnorderedList>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Paste components don't support a className property, why is that?
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <Paragraph mdxType="Paragraph">
        Whilst we are acutely aware that a Design System cannot do and hold everything that is needed for each
        individual product, and that extension and customization is the key to a successful Design System at scale, we
        will not be providing that functionality via a custom classname.
      </Paragraph>
      <Paragraph mdxType="Paragraph">We won't be doing this for a number of reasons:</Paragraph>
      <UnorderedList mdxType="UnorderedList">
        <ListItem mdxType="ListItem">
          Global classnames, especially overrides, impact the confidence teams can have on the state of their styling.
          There is no guarantee you won't affect someone else or they won't affect you, impacting the success of
          independent teams and confidence in your deployments. This particularly applies to a number of Twilio products
          that are multi-tenant UI, where multiple independent teams are providing UI to a single page
        </ListItem>
        <ListItem mdxType="ListItem">Uncontrolled customization causes design fragmentation</ListItem>
        <ListItem mdxType="ListItem">
          Uncontrolled customization impacts the ability of the Design System team to push changes to and evolve the
          Design System, without adversely impacting downstream consumers
        </ListItem>
      </UnorderedList>
      <Paragraph mdxType="Paragraph">
        Above all, custom classnames erode trust in the system, the components, the product and adversely affect the
        trust of the customer.
      </Paragraph>
      <Paragraph mdxType="Paragraph">
        Customization and extension will be provided, but it will be provided via a controlled API, within the
        constraints of the Design System. More on that soon.
      </Paragraph>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      Why does Paste use @twilio-paste/uid-library to generate ids? Why don't you use static ids?
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <Paragraph mdxType="Paragraph">
        ID references in a web page must be unique for them to work correctly, especially when supporting assistive
        technologies such as screen readers. In a multi tenant, single page application you do not always have control
        of what is loaded into a webpage at any one time. As a result static ids can clash and cause duplicates.
      </Paragraph>
      <Paragraph mdxType="Paragraph">
        To guarantee that every id in a web page is unique, regardless of what was loaded on the page and in what order,
        we use this library to generate ids for us.
      </Paragraph>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      OK, so you don't support classNames or static IDs, how do I create DOM references for my tests?
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <Paragraph mdxType="Paragraph">We recommend using data attributes for your test identifiers.</Paragraph>
      <Paragraph mdxType="Paragraph">
        These are your own implementation details and have no requirements to be unique.{' '}
        <InlineCode mdxType="InlineCode">data-testid</InlineCode> is a common identifier and supported by the very popular testing library{' '}
        <Anchor href="https://testing-library.com/docs/bs-react-testing-library/intro" mdxType="Anchor">React Testing Library</Anchor>
      </Paragraph>
      <Paragraph mdxType="Paragraph">
        All Paste components should support adding global HTML attributes as part of their API. So applying something
        like <InlineCode mdxType="InlineCode">data-testid="foo"</InlineCode> directly to the component should work.
      </Paragraph>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      What testing is done on Paste components?
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <Paragraph mdxType="Paragraph">
        We use Jest coupled with Enzyme for older components and React-Testing-Library for newer components to do
        component unit testing. We're transitioning away from Enzyme to React-Testing-Library in the long term as it is
        now the recommended testing library of the React team. We aim for 85-90% test coverage.
      </Paragraph>
      <Paragraph mdxType="Paragraph">
        We use Applitools for visual regression testing. We test each component, variant and state (where possible)
        separately. We only run them through Chrome currently.
      </Paragraph>
      <Paragraph mdxType="Paragraph">
        We also use Chromatic for UI review where any visual changes are reviewed by a designer for all components.
      </Paragraph>
      <Paragraph mdxType="Paragraph">All these tests are run on every commit and are required checks on all pull requests.</Paragraph>
    </DisclosureContent>
  </Disclosure>
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h3" variant="heading30" mdxType="DisclosureHeading">
      How do I get the fonts to load in my application?
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <Paragraph mdxType="Paragraph">
        Paste leaves loading the font files up to the consumer of the system, to accomodate any technical or licensing
        restrictions a product might have.
      </Paragraph>
      <Paragraph mdxType="Paragraph">
        For the default theme Paste uses Inter. This is available as a{' '}
        <Anchor href="https://fonts.google.com/specimen/Inter" mdxType="Anchor">Google web font</Anchor> can be loaded in a number of
        ways into your application. Please choose the one that best suits your preferred choice of asset delivery, or
        follow{' '}
        <Anchor href="https://github.com/twilio-labs/paste/blob/main/packages/paste-website/src/assets/css/fonts.css#L105" mdxType="Anchor">
          how we load the font
        </Anchor>{' '}
        on this website.
      </Paragraph>
      <Paragraph mdxType="Paragraph">
        For the Console theme, due to licensing restrictions only web properties under certain domains are allowed to
        load Whitney. As a result, Paste leaves loading Whitney up to the Console product itself. If you are building
        for a Twilio Console product, the font should be loaded for you automatically inside of Console.
      </Paragraph>
    </DisclosureContent>
  </Disclosure>
        </Stack>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      